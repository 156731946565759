<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <h3 class="mb-2">
            Facility
          </h3>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Facility Name"
            rules="required"
          >
            <b-form-group
              label="Facility Name"
              label-for="Facility Name"
            >
              <b-form-input
                id="facility-name"
                v-model="requestForm.facility.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Facility Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Facility Address"
            rules="required"
          >
            <b-form-group
              label="Facility Address"
              label-for="Facility Address"
            >

              <b-form-input
                id="facility-address"
                v-model="requestForm.facility.address"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Facility Address"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Facility Activity"
            rules="required"
          >
            <b-form-group
              label="Facility Activity"
              label-for="Facility Activity"
            >
              <b-form-input
                id="facility-activity"
                v-model="requestForm.facility.activity"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Facility Activity"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Facility Phone"
            rules="required"
          >
            <b-form-group
              label="Facility Phone"
              label-for="Facility Phone"
            >

              <b-form-input
                id="facility-phone"
                v-model="requestForm.facility.phone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Facility Phone"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          cols="12"
        >
          <h3 class="mb-2">
            Delegator
          </h3>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Delegator Name"
            rules="required"
          >
            <b-form-group
              label="Delegator Name"
              label-for="Delegator Name"
            >
              <b-form-input
                id="delegator-name"
                v-model="requestForm.delegator.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Delegator Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Delegator Email"
            rules="required"
          >
            <b-form-group
              label="Delegator Email"
              label-for="Delegator Email"
            >
              <b-form-input
                id="delegator-email"
                v-model="requestForm.delegator.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Delegator Email"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Delegator phone"
            rules="required"
          >
            <b-form-group
              label="Delegator phone"
              label-for="Delegator phone"
            >
              <b-form-input
                id="delegator-phone"
                v-model="requestForm.delegator.phone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Delegator phone"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          cols="12"
        >
          <h3 class="mb-2">
            Product
          </h3>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Product Name"
            rules="required"
          >
            <b-form-group
              label="Product Name"
              label-for="Product Name"
            >
              <b-form-input
                id="product-name"
                v-model="requestForm.product.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Product Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Product Brand"
            rules="required"
          >
            <b-form-group
              label="Product Brand"
              label-for="Product Brand"
            >
              <b-form-input
                id="product-brand_name"
                v-model="requestForm.product.brand_name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Product Brand"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="product HsCode_number"
            rules="required"
          >
            <b-form-group
              label="product HsCode_number"
              label-for="product HsCode_number"
            >
              <b-form-input
                id="product-HsCode_number"
                v-model="requestForm.product.HsCode_number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="product HsCode_number"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="product HsCode_desc"
            rules="required"
          >
            <b-form-group
              label="product HsCode_desc"
              label-for="product HsCode_desc"
            >
              <b-form-input
                id="product-HsCode_desc"
                v-model="requestForm.product.HsCode_desc"
                :state="getValidationState(validationContext)"
                trim
                placeholder="product HsCode_desc"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="product trademark"
            rules="required"
          >
            <b-form-group
              label="product trademark"
              label-for="product trademark"
            >
              <b-form-input
                id="product-trademark"
                v-model="requestForm.product.trademark"
                :state="getValidationState(validationContext)"
                trim
                placeholder="product trademark"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
        >
          <h3 class="mb-2">
            Certificates
          </h3>
        </b-col>
        <!-- Server Errors -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="certificate"
          >
            <b-form-group
              label="Certificate"
              label-for="certificate"
            >
              <b-form-radio-group
                id="certificates_id"
                v-model="requestForm.certificatesGroup"
                class="d-flex flex-column"
                :options="optionsStatusId"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-for="(group, index) in selectedCertificateGroups"
          :key="index"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            :name="`subCertificates${index}`"
          >
            <b-form-group
              :label="`Sub Certificates for ${group.text}`"
              :label-for="`sub_certificates_${index}`"
            >
              <b-form-radio-group
                :id="`sub_certificates_${index}`"
                v-model="requestForm.certificate_id"
                class="d-flex flex-column"
                :options="group.certificates"
                trim
                :state="getValidationState(validationContext)"
                multiple
              />
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="SetRequest()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="SetRequest()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {},
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      loader: false,
      validationErrors: {},
      errors: {},
      optionsStatusId: [],
      allCertificates: [],
      optionsStatus: [],
      loaderStatus: false,
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const requestForm = ref({
      facility: {
        name: '',
        activity: '',
        phone: '',
        address: '',
      },
      delegator: {
        name: '',
        email: '',
        phone: '',
      },
      product: {
        name: '',
        brand_name: '',
        HsCode_number: '',
        HsCode_desc: '',
        trademark: '',
      },
      certificatesGroup: '',
      certificate_id: '',
      // subCertificates
    })
    return {
      getValidationState,
      requestForm,
    }
  },
  computed: {
    // selectedCertificateGroups() {
    //   // console.log(this.allCertificates, this.requestForm.certificates)
    //   return this.requestForm.certificates
    //     .map(cert => {
    //       const group = this.allCertificates.find(g => g.value === cert)
    //       if (group) {
    //         return {
    //           ...group,
    //           certificates: group.certificates.map(certif => ({
    //             value: certif.id,
    //             text: `${certif.name.ar} - ${certif.name.en}`,
    //           })),
    //         }
    //       }
    //       return null
    //     })
    //     .filter(group => group !== null)
    // },
    selectedCertificateGroups() {
      if (!this.requestForm.certificatesGroup) return []

      const group = this.allCertificates.find(g => g.value === this.requestForm.certificatesGroup)
      if (group) {
        return [{
          ...group,
          certificates: group.certificates.map(certif => ({
            value: certif.id,
            text: `${certif.name.ar} - ${certif.name.en}`,
          })),
        }]
      }
      return []
    },
  },
  mounted() {
    this.getEngineerStatus()
  },
  methods: {
    getEngineerStatus() {
      this.loader = true
      axios
        .get('certificate')
        .then(res => {
          this.allCertificates = decryptData(res.data.payload).data.groups.map(item => ({
            value: item.name.ar,
            text: `${item.name.ar} - ${item.name.en}`,
            certificates: item.certificates,
          }))
          this.optionsStatusId = this.allCertificates.map(item => ({
            value: item.value,
            text: item.text,
          }))
        })
        .finally(() => {
          this.loader = false
        })
    },
    SetRequest() {
      this.loader = true
      axios
        .post('/requests', this.requestForm)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'requests' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
        })
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
